<template>
  <div class="page-box">
    <div class="list-query">
      名称：
      <Input v-model="form.name" type="text" clearable style="width: 100px; margin-right: 10px;"></Input>
      站点状态：
      <Select v-model="form.status" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <Option :value="1">正常</Option>
        <Option :value="2">维修</Option>
        <Option :value="3">停用</Option>
      </Select>
      监控器设备类型：
      <Select v-model="form.smallType" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <Option v-for="el,index in devicesTypes" :value="el.code" :key="index">{{el.name}}</Option>
      </Select>
      <Button type="primary" @click="getList()">查询</Button>
    </div>
    <div ref='table1' class="table1-data">
      <vxe-table border ref='xTable1' header-align="center" align="center" :height="tabHeight" :data="list">
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="name" title="名称" width="170"></vxe-column>
        <vxe-column field="groupName" title="所在分区"></vxe-column>
        <vxe-column field="state" title="综合状态"></vxe-column>
        <vxe-column field="sequence" title="时序号"></vxe-column>
        <vxe-column field="handState" title="手动状态"></vxe-column>
        <vxe-column field="remoteState" title="遥控状态"></vxe-column>
        <vxe-column field="timeState" title="时控状态"></vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :current-page="form.index" :page-size="form.size" :total="count" :page-sizes="[20, 100, 500, 1000, 5000]" :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="handlePageChange">
    </vxe-pager>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'switch',
  components: {
  },
  props: {

  },
  data() {
    return {
      list: [],
      devicesTypes: [],
      count: 0,
      tabHeight: 500,
      form: {
        "groupId": "", //用户选择的左树分区节点id，为空表示根节点或选择站点
        "stationId": "", //用户选择的左树站点节点ID，
        "name": "", //站点名称
        "code": "", //设备通信ID
        "type": 0, //站点类型
        "smallType": 0, //监控器设备类型
        "status": 0, //站点状态：0、全部；1、正常；2、维修；3、停用
        "index": 1, //分页码
        "size": 20, //分页大小
      }
    }
  },
  computed: {
    ...mapState('group', ['groupTreeSelectedNode']),
  },
  watch: {
    'groupTreeSelectedNode.id'() {
      this.getList();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.GetDevicesTypes();
    this.getList();
    setTimeout(this.setTableHeight, 200);
  },
  methods: {
    statusType(key) {
      let name = '正常'
      switch (key) {//1、正常；2、维修；3、停用
        case 1:
          name = '正常'
          break;
        case 2:
          name = '维修'
          break;
        case 3:
          name = '停用'
          break;
      }
      return name;
    },
    GetDevicesTypes() {
      this.$axios.post(`api/powerbox/GetDevicesTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'devicesTypes', res.data);
        }
      });
    },
    handlePageChange: function ({ currentPage, pageSize }) {
      this.form.index = currentPage;
      this.form.size = pageSize;
      this.getList(true);
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 20;
    },
    GetProjectInvites() {
      this.showGetProjectInvites = true
    },
    getList(is) {
      if (is) { this.form.index = 1; }
      if (this.groupTreeSelectedNode.typeTitle == "group") {
        this.form.groupId = this.groupTreeSelectedNode.id;
        this.form.stationId = '';
      } else if (this.groupTreeSelectedNode.typeTitle == "station") {
        this.form.groupId = this.groupTreeSelectedNode.groupId;
        this.form.stationId = this.groupTreeSelectedNode.id;
      }
      this.$axios.post(`api/powerbox/GetSwitchStateList`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.count = res.data.count
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: calc(100%);
  height: calc(100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.table1-data {
  height: 100px;
  flex: auto;
}
</style>
