<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">支路线缆信息</div>
    <div>
      <Form ref="form" :model="form" :label-width="110">
        <FormItem prop="name" label="名称">
          <Input v-model="form.name" style="width: 200px" class="mr10"></Input>
          关联接触器
          <Select :placeholder="showLang('save.select')" v-model="form.contactId" style="width: 200px;" class="mr10">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <template v-for="(s, idx) in contactConfigList">
              <Option :value="s.id" :key="idx">{{s.name}}</Option>
            </template>
          </Select>
          {{showLang('com.state.police.type')}}
          <i-switch v-model="form.alarmByCurrent" size="large" true-color="green" false-color="blue" style="margin: 0 10px;width:56px">
            <span slot="open">{{showLang('com.lighting.I')}}</span>
            <span slot="close">{{showLang('com.lighting.P')}}</span>
          </i-switch>
          {{showLang('com.state.police.method')}}
          <i-switch v-model="form.alarmByRated" size="large" true-color="green" false-color="blue" style="margin: 0 10px;width:56px">
            <span slot="open">{{showLang('com.lighting.rated')}}</span>
            <span slot="close">{{showLang('com.state.ratio')}}</span>
          </i-switch>
        </FormItem>
        <FormItem prop="par" :label="showLang('cmd.project.A.P')" style="margin-right: 15px">
          <div class="flex-row">
            {{showLang('com.lighting.capacity')}}
            <Input type="number" v-model="form.currentA.powerRated" style="width: 100px; margin: 0 10px;"><span slot="append">W</span></Input>
            {{showLang('com.lighting.pah')}}
            <Input type="number" v-model="form.currentA.powerUpper" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
            {{showLang('com.lighting.paf')}}
            <Input type="number" v-model="form.currentA.powerFactorLower" style="width: 100px; margin: 0 10px;"></Input>
          </div>
        </FormItem>
        <FormItem prop="pbr" :label="showLang('cmd.project.B.P')" style="margin-right: 15px">
          <div class="flex-row">
            {{showLang('com.lighting.capacity')}}
            <Input type="number" v-model="form.currentB.powerRated" style="width: 100px; margin: 0 10px;"><span slot="append">W</span></Input>
            {{showLang('com.lighting.pah')}}
            <Input type="number" v-model="form.currentB.powerUpper" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
            {{showLang('com.lighting.paf')}}
            <Input type="number" v-model="form.currentB.powerFactorLower" style="width: 100px; margin: 0 10px;"></Input>
          </div>
        </FormItem>
        <FormItem prop="pcr" :label="showLang('cmd.project.C.P')" style="margin-right: 15px">
          <div class="flex-row">
            {{showLang('com.lighting.capacity')}}
            <Input type="number" v-model="form.currentC.powerRated" style="width: 100px; margin: 0 10px;"><span slot="append">W</span></Input>
            {{showLang('com.lighting.pah')}}
            <Input type="number" v-model="form.currentC.powerUpper" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
            {{showLang('com.lighting.paf')}}
            <Input type="number" v-model="form.currentC.powerFactorLower" style="width: 100px; margin: 0 10px;"></Input>
          </div>
        </FormItem>
        <FormItem prop="cac" :label="showLang('cmd.project.A.I')" style="margin-right: 15px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.currentA.channel" style="width: 150px; margin-right: 5px;">
              <Option :value="0">{{showLang('com.state.not')}}</Option>
              <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
            </Select>
            <Input type="number" v-model="form.currentA.currentRate" style="width: 200px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span slot="append">{{showLang('com.data.times')}}</span></Input>
            <Input type="number" v-model="form.currentA.currentRated" style="width: 170px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span slot="append">A</span></Input>
            <Input type="number" v-model="form.currentA.currentLower" style="width: 160px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span slot="append">%</span></Input>
            <Input type="number" v-model="form.currentA.currentUpper" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span slot="append">%</span></Input>
            <Input type="number" v-model="form.currentA.extra" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span slot="append">A</span></Input>
            <Input type="number" v-model="form.currentA.times" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.multiplier')}}</span></Input>
          </div>
        </FormItem>
        <FormItem prop="cbc" :label="showLang('cmd.project.B.I')" style="margin-right: 15px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.currentB.channel" style="width: 150px; margin-right: 5px;">
              <Option :value="0">{{showLang('com.state.not')}}</Option>
              <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
            </Select>
            <Input type="number" v-model="form.currentB.currentRate" style="width: 200px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span slot="append">{{showLang('com.data.times')}}</span></Input>
            <Input type="number" v-model="form.currentB.currentRated" style="width: 170px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span slot="append">A</span></Input>
            <Input type="number" v-model="form.currentB.currentLower" style="width: 160px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span slot="append">%</span></Input>
            <Input type="number" v-model="form.currentB.currentUpper" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span slot="append">%</span></Input>
            <Input type="number" v-model="form.currentB.extra" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span slot="append">A</span></Input>
            <Input type="number" v-model="form.currentB.times" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.multiplier')}}</span></Input>
          </div>
        </FormItem>
        <FormItem prop="ccc" :label="showLang('cmd.project.C.I')" style="margin-right: 15px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.currentC.channel" style="width: 150px; margin-right: 5px;">
              <Option :value="0">{{showLang('com.state.not')}}</Option>
              <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
            </Select>
            <Input type="number" v-model="form.currentC.currentRate" style="width: 200px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span slot="append">{{showLang('com.data.times')}}</span></Input>
            <Input type="number" v-model="form.currentC.currentRated" style="width: 170px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span slot="append">A</span></Input>
            <Input type="number" v-model="form.currentC.currentLower" style="width: 160px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span slot="append">%</span></Input>
            <Input type="number" v-model="form.currentC.currentUpper" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span slot="append">%</span></Input>
            <Input type="number" v-model="form.currentC.extra" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span slot="append">A</span></Input>
            <Input type="number" v-model="form.currentC.times" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.multiplier')}}</span></Input>
          </div>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button type="info" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLineConfigEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      showMapModal: false,
      contactConfigList: [],
      timeTables: [],
      indexVal: 0,
      form: {
        "id": "",
        "contactId": "", //关联接触器ID
        "name": null, //名称
        "alarmByCurrent": false, //是否以电流报警，否则以功率报警
        "alarmByRated": false, //是否以额定值及上下限报警，否则以历史对比值报警
        "currentA": { //A相能耗监测信息
          "channel": 0, //电流通道
          "powerRated": 220, //额定功率
          "powerUpper": 80, //功率过载阈值
          "powerFactorLower": 0.8, //功率因数阈值
          "currentRate": 1, //电流倍率
          "currentRated": 0, //额定值
          "currentLower": 80, //下限值百分比
          "currentUpper": 120, //上限值百分比
          "extra": 0, //外接电阈值
          "times": 1 //倍乘数
        },
        "currentB": {
          "channel": 0,
          "powerRated": 220,
          "powerUpper": 80,
          "powerFactorLower": 0.8,
          "currentRate": 1,
          "currentRated": 0,
          "currentLower": 80,
          "currentUpper": 120,
          "extra": 0,
          "times": 1
        },
        "currentC": {
          "channel": 0,
          "powerRated": 220,
          "powerUpper": 80,
          "powerFactorLower": 0.8,
          "currentRate": 1,
          "currentRated": 0,
          "currentLower": 80,
          "currentUpper": 120,
          "extra": 0,
          "times": 1
        },
        "points": [ //线缆的走向点集合，从配电柜位置为起点，但不包含配电柜所在的位置
          {
            "lat": '',
            "lng": '',
          }
        ]
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.dataAjax();
        this.GetLineConfigDesc(this.item.data.id)
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
    ...mapState('sys', ['myMenus']),
  },
  mounted: function () {
  },
  methods: {
    posSelected: function (params) {
      this.form.points[0].lat = params.lat;
      this.form.points[0].lng = params.lng;
    },
    GetLineConfigDesc(id) {
      this.$axios.post(`api/powerbox/GetLineStateDesc`, { id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'form', res.data);
        }
      });
    },
    dataAjax() {
      this.$axios.post(`api/powerbox/GetTimeList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeTables', res.data);
        }
      });
      let ajaxData = {
        "groupId": "", //用户选择的左树分区节点id，为空表示根节点或选择站点
        "stationId": "", //用户选择的左树站点节点ID，
        "name": "", //站点名称
        "channel": 0, //控制通道号，为0选全部
        "index": 1, //分页码
        "size": 20000000, //分页大小
      }
      this.contactConfigList = [];
      if (this.groupTreeSelectedNode.typeTitle == "group") {
        ajaxData.groupId = this.groupTreeSelectedNode.id;
        ajaxData.stationId = '';
      } else if (this.groupTreeSelectedNode.typeTitle == "station") {
        ajaxData.groupId = this.groupTreeSelectedNode.groupId;
        ajaxData.stationId = this.groupTreeSelectedNode.id;
      }
      this.$axios.post(`api/powerbox/GetContactConfigList`, ajaxData).then(res => {
        if (res.code == 0) {
          this.$set(this, 'contactConfigList', res.data.list);
        }
      });
    },
    ok() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
</style>
