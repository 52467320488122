<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px" :styles="{top: '10px'}">
    <div slot="header">{{showLang('com.but.edit.boxInfo')}}</div>
    <Form ref="form" :model="form" :label-width="110" :key="fontIndex">
      <div class="form-item-group">
        <div class="group-header">{{showLang('com.but.basic.info')}}</div>
        <FormItem prop="name" :label="showLang('dev.main.name')" style="margin-right: 15px">
          <div class="flex-row">
            <Input type="text" v-model="form.name" :placeholder="showLang('dev.main.name')" style="width: 200px; margin-right: 10px;"></Input>
            所在分区
            <Select placeholder="所在分区" v-model="form.groupId" style="width: 200px; margin: 0 10px;" :key="indexVal">
              <Option v-for="(s, idx) in groupList" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            安装类型
            <Select :placeholder="showLang('save.select')" v-model="form.type" style="width: 200px; margin: 0 10px;">
              <Option v-for="(s, idx) in typeData" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
          </div>
        </FormItem>
        <FormItem prop="connectType" label="监控器类型" style="margin-right: 15px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.smallType" style="width: 200px;margin-right: 10px">
              <Option v-for="(dev, didx) in devicesTypes" :key="didx" :value="dev.code">{{dev.name}}</Option>
            </Select>
            通信模块类型
            <Select :placeholder="showLang('save.select')" v-model="form.transType" style="width: 200px; margin: 0 10px;">
              <Option :value="0">无</Option>
              <Option v-for="(s, idx1) in transTypes" :key="idx1" :value="s.code">{{s.name}}</Option>
            </Select>
            通信ID
            <Input type="text" v-model="form.code" placeholder="通信ID" style="width: 200px; margin:0 10px;"></Input>
          </div>
        </FormItem>
        <FormItem prop="power" :label="showLang('com.op.locus.control.monitor')" style="margin-right: 10px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.innerControlChannel" style="width: 200px; margin-right: 10px;">
              <Option :value="0">{{showLang('com.data.not.monitored')}}</Option>
              <template v-for="n in 16">
                <Option :value="n" :key="n">S{{n}}</Option>
              </template>
            </Select>
            {{showLang('com.op.internal.val')}}
            <Select :placeholder="showLang('save.select')" v-model="form.innerControlState" style="width: 200px; margin: 0 10px;">
              <Option :value="0">{{showLang('com.state.break')}}</Option>
              <Option :value="1">{{showLang('com.state.connection')}}</Option>
            </Select>
            <span style="color:red">{{showLang('save.monitor.ch.dev.control')}}</span>
          </div>
        </FormItem>
        <FormItem prop="power" label="安装时间" style="margin-right: 10px">
          <div class="flex-row">
            <DatePicker type="date" v-model="form.buildDate" placeholder="安装时间" style="width: 200px;margin-right: 10px;" />
            安装位置
            <Input v-model="form.location" placeholder="安装位置" style="width: 200px; margin: 0 10px;"></Input>
            经度
            <Input type="number" v-model="form.lng" placeholder="经度" disabled style="width: 140px; margin: 0 10px;"></Input>
            纬度
            <Input type="number" v-model="form.lat" placeholder="纬度" disabled style="width: 140px; margin: 0 10px;"></Input>
            <Button type="info" style="margin-right: 8px" @click="showMapModal=true">选择位置</Button>

          </div>
        </FormItem>
      </div>
      <div class="form-item-group">
        <div class="group-header">{{showLang('com.lighting.V.monitor')}}</div>
        <FormItem prop="enu" :label="showLang('com.ins.timeTable.alarm')" style="margin-right: 15px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.voltageTimeId" style="width: 475px;">
              <Option :value="0">{{showLang('com.state.not')}}</Option>
              <template v-for="(s, idx) in timeTables">
                <Option :value="s.id" :key="idx">{{s.name}}</Option>
              </template>
            </Select>
          </div>
        </FormItem>
        <FormItem prop="uar" :label="showLang('cmd.project.A.V')" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form.voltageAlarmA" size="large" true-color="green" false-color="red" style="margin: 0 5px;width:56px">
              <span slot="open">{{showLang('com.state.firing')}}</span>
              <span slot="close">{{showLang('com.state.prohibit')}}</span>
            </i-switch>
            {{showLang('com.lighting.rated.v.val')}}
            <Input type="number" v-model="form.voltageRatedA" style="width: 100px; margin: 0 10px;"><span slot="append">V</span></Input>
            {{showLang('com.data.lower.val')}}
            <Input type="number" v-model="form.voltageLowerA" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
            {{showLang('com.data.upper.val')}}
            <Input type="number" v-model="form.voltageUpperA" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>

          </div>
        </FormItem>
        <FormItem prop="ubr" :label="showLang('cmd.project.B.V')" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form.voltageAlarmB" size="large" true-color="green" false-color="red" style="margin: 0 5px;width:56px">
              <span slot="open">{{showLang('com.state.firing')}}</span>
              <span slot="close">{{showLang('com.state.prohibit')}}</span>
            </i-switch>
            {{showLang('com.lighting.rated.v.val')}}
            <Input type="number" v-model="form.voltageRatedB" style="width: 100px; margin: 0 10px;"><span slot="append">V</span></Input>
            {{showLang('com.data.lower.val')}}
            <Input type="number" v-model="form.voltageLowerB" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
            {{showLang('com.data.upper.val')}}
            <Input type="number" v-model="form.voltageUpperB" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
          </div>
        </FormItem>
        <FormItem prop="ucr" :label="showLang('cmd.project.C.V')" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form.voltageAlarmC" size="large" true-color="green" false-color="red" style="margin: 0 5px;width:56px">
              <span slot="open">{{showLang('com.state.firing')}}</span>
              <span slot="close">{{showLang('com.state.prohibit')}}</span>
            </i-switch>
            {{showLang('com.lighting.rated.v.val')}}
            <Input type="number" v-model="form.voltageRatedC" style="width: 100px; margin: 0 10px;"><span slot="append">V</span></Input>
            {{showLang('com.data.lower.val')}}
            <Input type="number" v-model="form.voltageLowerC" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
            {{showLang('com.data.upper.val')}}
            <Input type="number" v-model="form.voltageUpperC" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
          </div>
        </FormItem>
      </div>
      <div class="form-item-group">
        <div class="group-header">{{showLang('com.stat.q.correlation')}}</div>
        <FormItem prop="enp" :label="showLang('com.is.enable')" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form.enableCurrent" size="large" true-color="green" false-color="red" style="margin-right: 10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            {{showLang('com.ins.timeTable.alarm')}}
            <Select :placeholder="showLang('save.select')" v-model="form.currentTimeId" style="width: 200px; margin: 0 10px;">
              <Option :value="0">{{showLang('com.state.not')}}</Option>
              <template v-for="(s, idx) in timeTables">
                <Option :value="s.id" :key="idx">{{s.name}}</Option>
              </template>
            </Select>
            {{showLang('com.state.police.type')}}
            <i-switch v-model="form.alarmByCurrent" size="large" true-color="green" false-color="blue" style="margin: 0 10px;width:56px">
              <span slot="open">{{showLang('com.lighting.I')}}</span>
              <span slot="close">{{showLang('com.lighting.P')}}</span>
            </i-switch>
            {{showLang('com.state.police.method')}}
            <i-switch v-model="form.alarmByRated" size="large" true-color="green" false-color="blue" style="margin: 0 10px;width:56px">
              <span slot="open">{{showLang('com.lighting.rated')}}</span>
              <span slot="close">{{showLang('com.state.ratio')}}</span>
            </i-switch>
          </div>
        </FormItem>
        <div v-show="form.enableCurrent">
          <FormItem prop="par" :label="showLang('cmd.project.A.P')" style="margin-right: 15px">
            <div class="flex-row">
              {{showLang('com.lighting.capacity')}}
              <Input type="number" v-model="form.currentA.powerRated" style="width: 100px; margin: 0 10px;"><span slot="append">W</span></Input>
              {{showLang('com.lighting.pah')}}
              <Input type="number" v-model="form.currentA.powerUpper" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
              {{showLang('com.lighting.paf')}}
              <Input type="number" v-model="form.currentA.powerFactorLower" style="width: 100px; margin: 0 10px;"></Input>
            </div>
          </FormItem>
          <FormItem prop="pbr" :label="showLang('cmd.project.B.P')" style="margin-right: 15px">
            <div class="flex-row">
              {{showLang('com.lighting.capacity')}}
              <Input type="number" v-model="form.currentB.powerRated" style="width: 100px; margin: 0 10px;"><span slot="append">W</span></Input>
              {{showLang('com.lighting.pah')}}
              <Input type="number" v-model="form.currentB.powerUpper" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
              {{showLang('com.lighting.paf')}}
              <Input type="number" v-model="form.currentB.powerFactorLower" style="width: 100px; margin: 0 10px;"></Input>
            </div>
          </FormItem>
          <FormItem prop="pcr" :label="showLang('cmd.project.C.P')" style="margin-right: 15px">
            <div class="flex-row">
              {{showLang('com.lighting.capacity')}}
              <Input type="number" v-model="form.currentC.powerRated" style="width: 100px; margin: 0 10px;"><span slot="append">W</span></Input>
              {{showLang('com.lighting.pah')}}
              <Input type="number" v-model="form.currentC.powerUpper" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
              {{showLang('com.lighting.paf')}}
              <Input type="number" v-model="form.currentC.powerFactorLower" style="width: 100px; margin: 0 10px;"></Input>
            </div>
          </FormItem>
          <FormItem prop="cac" :label="showLang('cmd.project.A.I')" style="margin-right: 15px">
            <div class="flex-row">
              <Select :placeholder="showLang('save.select')" v-model="form.currentA.channel" style="width: 150px; margin-right: 5px;">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
              </Select>
              <Input type="number" v-model="form.currentA.currentRate" style="width: 200px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span slot="append">{{showLang('com.data.times')}}</span></Input>
              <Input type="number" v-model="form.currentA.currentRated" style="width: 170px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span slot="append">A</span></Input>
              <Input type="number" v-model="form.currentA.currentLower" style="width: 160px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span slot="append">%</span></Input>
              <Input type="number" v-model="form.currentA.currentUpper" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span slot="append">%</span></Input>
              <Input type="number" v-model="form.currentA.extra" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span slot="append">A</span></Input>
              <Input type="number" v-model="form.currentA.times" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.multiplier')}}</span></Input>
            </div>
          </FormItem>
          <FormItem prop="cbc" :label="showLang('cmd.project.B.I')" style="margin-right: 15px">
            <div class="flex-row">
              <Select :placeholder="showLang('save.select')" v-model="form.currentB.channel" style="width: 150px; margin-right: 5px;">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
              </Select>
              <Input type="number" v-model="form.currentB.currentRate" style="width: 200px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span slot="append">{{showLang('com.data.times')}}</span></Input>
              <Input type="number" v-model="form.currentB.currentRated" style="width: 170px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span slot="append">A</span></Input>
              <Input type="number" v-model="form.currentB.currentLower" style="width: 160px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span slot="append">%</span></Input>
              <Input type="number" v-model="form.currentB.currentUpper" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span slot="append">%</span></Input>
              <Input type="number" v-model="form.currentB.extra" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span slot="append">A</span></Input>
              <Input type="number" v-model="form.currentB.times" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.multiplier')}}</span></Input>
            </div>
          </FormItem>
          <FormItem prop="ccc" :label="showLang('cmd.project.C.I')" style="margin-right: 15px">
            <div class="flex-row">
              <Select :placeholder="showLang('save.select')" v-model="form.currentC.channel" style="width: 150px; margin-right: 5px;">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
              </Select>
              <Input type="number" v-model="form.currentC.currentRate" style="width: 200px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span slot="append">{{showLang('com.data.times')}}</span></Input>
              <Input type="number" v-model="form.currentC.currentRated" style="width: 170px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span slot="append">A</span></Input>
              <Input type="number" v-model="form.currentC.currentLower" style="width: 160px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span slot="append">%</span></Input>
              <Input type="number" v-model="form.currentC.currentUpper" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span slot="append">%</span></Input>
              <Input type="number" v-model="form.currentC.extra" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span slot="append">A</span></Input>
              <Input type="number" v-model="form.currentC.times" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.multiplier')}}</span></Input>
            </div>
          </FormItem>
        </div>
      </div>
    </Form>
    <ModalSelectMapPoint v-model="showMapModal" :custCenter="pos" @posSelected="posSelected" />
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>

import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalMainEdit',
  components: {
    ModalSelectMapPoint,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    stations: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showMapModal: false,
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      contacts: [],
      devices: [],
      timeTables: [],
      fontIndex: 1,
      indexVal: 1,
      typeData: [
        { id: 1, name: '配电室' },
        { id: 2, name: '箱变' },
        { id: 3, name: '落地式' },
        { id: 4, name: '柱挂式' },
        { id: 5, name: '墙挂式' },
        { id: 6, name: '嵌入式' },
      ],
      form: {
        id: 0,
        name: '',
        type: 1,
        "groupId": "", //所在分区
        "code": null, //通信ID
        "smallType": 0, //监控器类型
        "transType": 0, //通信模块类型
        "location": "-", //安装位置
        "buildDate": "-", //安装时间
        "lat": 0, //位置，纬度
        "lng": 0, //位置,经度
        "voltageTimeId": "", //电压报警时间表
        "voltageAlarmA": true, //A相电压是否报警
        "voltageRatedA": 220, //A相电压额定值
        "voltageLowerA": 80, //A相电压下限值比例
        "voltageUpperA": 120, //A相电压上限值比例
        "voltageAlarmB": true,
        "voltageRatedB": 220,
        "voltageLowerB": 80,
        "voltageUpperB": 120,
        "voltageAlarmC": true,
        "voltageRatedC": 220,
        "voltageLowerC": 80,
        "voltageUpperC": 120,
        "innerControlChannel": 0, //内外控监测通道值，为0不监测
        "innerControlState": 0, //内控状态值
        "enableCurrent": false, //是否启用能耗监测
        "currentTimeId": "", //能耗监测报警时间表
        "alarmByCurrent": false, //是否以电流报警，否则以功率报警
        "alarmByRated": false, //是否以额定值及上下限报警，否则以历史对比值报警
        "currentA": { //A相能耗监测信息
          "channel": 0, //电流通道
          "powerRated": 220, //额定功率
          "powerUpper": 80, //功率过载阈值
          "powerFactorLower": 0.8, //功率因数阈值
          "currentRate": 1, //电流倍率
          "currentRated": 0, //额定值
          "currentLower": 80, //下限值百分比
          "currentUpper": 120, //上限值百分比
          "extra": 0, //外接电阈值
          "times": 1 //倍乘数
        },
        "currentB": {
          "channel": 0,
          "powerRated": 220,
          "powerUpper": 80,
          "powerFactorLower": 0.8,
          "currentRate": 1,
          "currentRated": 0,
          "currentLower": 80,
          "currentUpper": 120,
          "extra": 0,
          "times": 1
        },
        "currentC": {
          "channel": 0,
          "powerRated": 220,
          "powerUpper": 80,
          "powerFactorLower": 0.8,
          "currentRate": 1,
          "currentRated": 0,
          "currentLower": 80,
          "currentUpper": 120,
          "extra": 0,
          "times": 1
        }
      },
      pos: {
        area: '',
        lat: 0,
        lng: 0,
      },
      transTypes: [],
      devicesTypes: [],
      groupList: [],
      groupData: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          this.form.stationId = this.item.data.stationId;
          this.form.name = this.item.data.name;
          this.form.groupId = this.groupTreeSelectedNode.id;
          this.form.deviceId = this.item.data.deviceId;
          for (let k in this.form.content) {
            this.form.content[k] = this.item.data.content[k];
          }
          this.fontIndex++;
        } else {
          this.form.id = 0;
          this.form.name = this.showLang('dev.main');
          this.form = {
            'type': 1,
            "groupId": "", //所在分区
            "code": null, //通信ID
            "smallType": 0, //监控器类型
            "transType": 0, //通信模块类型
            "location": "-", //安装位置
            "buildDate": "-", //安装时间
            "lat": 0, //位置，纬度
            "lng": 0, //位置,经度
            "voltageTimeId": "", //电压报警时间表
            "voltageAlarmA": true, //A相电压是否报警
            "voltageRatedA": 220, //A相电压额定值
            "voltageLowerA": 80, //A相电压下限值比例
            "voltageUpperA": 120, //A相电压上限值比例
            "voltageAlarmB": true,
            "voltageRatedB": 220,
            "voltageLowerB": 80,
            "voltageUpperB": 120,
            "voltageAlarmC": true,
            "voltageRatedC": 220,
            "voltageLowerC": 80,
            "voltageUpperC": 120,
            "innerControlChannel": 0, //内外控监测通道值，为0不监测
            "innerControlState": 0, //内控状态值
            "enableCurrent": false, //是否启用能耗监测
            "currentTimeId": "", //能耗监测报警时间表
            "alarmByCurrent": false, //是否以电流报警，否则以功率报警
            "alarmByRated": false, //是否以额定值及上下限报警，否则以历史对比值报警
            "currentA": { //A相能耗监测信息
              "channel": 0, //电流通道
              "powerRated": 220, //额定功率
              "powerUpper": 80, //功率过载阈值
              "powerFactorLower": 0.8, //功率因数阈值
              "currentRate": 1, //电流倍率
              "currentRated": 0, //额定值
              "currentLower": 80, //下限值百分比
              "currentUpper": 120, //上限值百分比
              "extra": 0, //外接电阈值
              "times": 1 //倍乘数
            },
            "currentB": {
              "channel": 0,
              "powerRated": 220,
              "powerUpper": 80,
              "powerFactorLower": 0.8,
              "currentRate": 1,
              "currentRated": 0,
              "currentLower": 80,
              "currentUpper": 120,
              "extra": 0,
              "times": 1
            },
            "currentC": {
              "channel": 0,
              "powerRated": 220,
              "powerUpper": 80,
              "powerFactorLower": 0.8,
              "currentRate": 1,
              "currentRated": 0,
              "currentLower": 80,
              "currentUpper": 120,
              "extra": 0,
              "times": 1
            }
          }
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['groupTreeSelectedNode']),
    boxType: function () {
      return function (deviceId) {
        // if (!this.selectedStation) return 'none';
        if (deviceId <= 0) return 'none';
        let devs = this.devices.filter(p => p.id == deviceId);
        if (devs.length == 0) return 'none';
        return devs[0].type;
      }
    },
  },
  mounted: function () {
    this.typeAjax();
  },
  methods: {
    posSelected: function (params) {
      this.form.location = params.address;
      this.form.lat = params.lat;
      this.form.lng = params.lng;
    },
    typeAjax: function () {
      this.$axios.post(`api/powerbox/GetTimeList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeTables', res.data);
        }
      });
      this.$axios.post(`api/powerbox/GetDevicesTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'devicesTypes', res.data);
        }
      });
      this.$axios.post(`api/powerbox/GetTransTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'transTypes', res.data);
        }
      });
      this.$axios.post(`api/auth/GetMyGroups`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'groupList', res.data);
          // this.groupData = this.buildTree(res.data)
          // this.groupList = [];
          // let arr = this.findByNameAndSubsets(this.groupData, this.groupTreeSelectedNode.id);
          // this.checkboxIsAll(arr[0]);
          // this.indexVal++;
        }
      });
    },
    buildTree(items, parentId = '') {
      return items.filter(item => item.parentId === parentId).map(item => ({ ...item, children: this.buildTree(items, item.id), }));
    },
    findByNameAndSubsets(groups, id) {
      let result = [];
      function traverse(group) {
        if (group.id === id) {
          result.push({ ...group }); // 使用解构赋值来复制对象，避免直接修改原始数据
        }

        if (group.children && group.children.length > 0) {
          group.children.forEach(child => {
            traverse(child);
          });
        }
      }
      groups.forEach(group => {
        traverse(group);
      });

      return result;
    },
    checkboxIsAll(data) {
      this.groupList.push(data);
      if (data.children.length == 0) return false;
      data.children.forEach(childNode => {
        this.groupList.push(childNode);
        this.setCheckbox(childNode);
      });
    },
    setCheckbox(node) {
      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => {
          this.setCheckbox(childNode);
          this.groupList.push(childNode);
        });
      }
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.dev.main.name'));
        return;
      }
      // if (this.form.stationId <= 0) {
      //   this.$Message.warning(this.showLang('com.save.err.station'));
      //   return;
      // }
      // if (this.form.deviceId <= 0) {
      //   this.$Message.warning(this.showLang('save.light.device.need'));
      //   return;
      // }
      // if (this.form.content.ll <= 0) {
      //   this.$Message.warning(this.showLang('save.light.range.leakage1', 0));
      //   return;
      // }
      // if (this.form.content.lh <= this.form.content.ll) {
      //   this.$Message.warning(this.showLang('save.light.range.leakage2'));
      //   return;
      // }
      // if (this.form.content.lx <= this.form.content.lh) {
      //   this.$Message.warning(this.showLang('save.light.range.leakage3'));
      //   return;
      // }
      this.$axios.post(`api/powerbox/SaveStationConfigMain`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>