<template>
  <div class="page-box">
    <div class="list-query">
      名称：
      <Input v-model="form.name" type="text" clearable style="width: 100px; margin-right: 10px;"></Input>
      控制通道号：
      <Select :placeholder="showLang('save.select')" v-model="form.channel" style="width: 200px;margin-right: 20px;">
        <Option :value="0">全部</Option>
        <Option v-for="n in 16" :key="n" :value="n">{{showLang('com.lighting.input.which',n)}}</Option>
      </Select>

      <Button type="primary" @click="getList()">查询</Button>
    </div>
    <div ref='table1' class="table1-data">
      <vxe-table border ref='xTable1' header-align="center" align="center" :height="tabHeight" :data="list">
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="name" title="名称"></vxe-column>
        <vxe-column field="stationName" title="所在站点"></vxe-column>
        <vxe-column field="outputName" title="关联控制输出"></vxe-column>
        <vxe-column field="state" title="状态监测值"></vxe-column>
        <vxe-column field="channel" title="状态监测通道"></vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :current-page="form.index" :page-size="form.size" :total="count" :page-sizes="[20, 100, 500, 1000, 5000]" :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="handlePageChange">
    </vxe-pager>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'contactConfig',
  components: {
  },
  props: {

  },
  data() {
    return {
      list: [],
      devicesTypes: [],
      count: 0,
      tabHeight: 500,
      form: {
        "groupId": "", //用户选择的左树分区节点id，为空表示根节点或选择站点
        "stationId": "", //用户选择的左树站点节点ID，
        "name": "", //站点名称
        "channel": 0, //控制通道号，为0选全部
        "index": 1, //分页码
        "size": 20, //分页大小
      }
    }
  },
  computed: {
    ...mapState('group', ['groupTreeSelectedNode']),
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    'groupTreeSelectedNode.id'() {
      this.getList();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.GetDevicesTypes();
    this.getList();
    setTimeout(this.setTableHeight, 200);
  },
  methods: {
    GetDevicesTypes() {
      this.$axios.post(`api/powerbox/GetDevicesTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'devicesTypes', res.data);
        }
      });
    },
    handlePageChange: function ({ currentPage, pageSize }) {
      this.form.index = currentPage;
      this.form.size = pageSize;
      this.getList(true);
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 20;
    },
    getList(is) {
      if (is) { this.form.index = 1; }
      if (this.groupTreeSelectedNode.typeTitle == "group") {
        this.form.groupId = this.groupTreeSelectedNode.id;
        this.form.stationId = '';
      } else if (this.groupTreeSelectedNode.typeTitle == "station") {
        this.form.groupId = this.groupTreeSelectedNode.groupId;
        this.form.stationId = this.groupTreeSelectedNode.id;
      }
      this.$axios.post(`api/powerbox/GetContactStateList`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.count = res.data.count
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: calc(100%);
  height: calc(100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.table1-data {
  height: 100px;
  flex: auto;
}
</style>
