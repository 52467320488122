<template>
  <div class="page-box">
    <div class="list-query">
      名称：
      <Input v-model="form.name" type="text" clearable style="width: 100px; margin-right: 10px;"></Input>
      站点状态：
      <Select v-model="form.status" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <Option :value="1">正常</Option>
        <Option :value="2">维修</Option>
        <Option :value="3">停用</Option>
      </Select>
      在线状态：
      <Select v-model="form.online" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <Option :value="1">在线</Option>
        <Option :value="2">离线</Option>
      </Select>
      报警状态：
      <Select v-model="form.alarm" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <Option :value="1">报警</Option>
        <Option :value="2">正常</Option>
      </Select>
      亮灯状态：
      <Select v-model="form.running" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <Option :value="1">亮灯</Option>
        <Option :value="2">灭灯</Option>
      </Select>
      <Button type="primary" @click="getList()">查询</Button>
    </div>
    <div class="table1-data" ref='table1'>
      <vxe-table border ref='xTable1' header-align="center" align="center" :height="tabHeight" :row-config="{isHover: true}" :data="list" :radio-config="{ highlight: true}" class="mt20">
        <vxe-column type="seq" width="60" fixed="left"></vxe-column>
        <vxe-column field="name" title="名称" width="170"  fixed="left"></vxe-column>
        <vxe-column field="groupName" title="所在分区" width="170"  fixed="left"></vxe-column>
        <vxe-column field="code" title="通信ID" width="170"></vxe-column>
        <vxe-column field="status" title="在线" width="80">
          <template #default="{ row }">
            {{row.online?'是':'否'}}
          </template>
        </vxe-column>
        <vxe-column field="alarm" title="报警" width="80">
          <template #default="{ row }">
            {{row.alarm?'是':'否'}}
          </template>
        </vxe-column>
        <vxe-column field="running" title="供电" width="80">
          <template #default="{ row }">
            {{row.running?'是':'否'}}
          </template>
        </vxe-column>
        <vxe-column field="transName" title="通信模块" width="170"></vxe-column>
        <vxe-column field="smallName" title="监控器类型" width="170"></vxe-column>
        <vxe-column field="voltageA" title="A相电压" width="170"></vxe-column>
        <vxe-column field="voltageB" title="B相电压" width="170"></vxe-column>
        <vxe-column field="voltageC" title="C相电压" width="170"></vxe-column>
        <vxe-column field="energy" title="电能值" width="170"></vxe-column>
        <vxe-column field="temperature" title="温度" width="170"></vxe-column>
        <vxe-column field="innerControlValue" title="内外控监测值" width="170"></vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :current-page="form.index" :page-size="form.size" :total="count" :page-sizes="[20, 100, 500, 1000, 5000]" :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="handlePageChange">
    </vxe-pager>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'stationList',
  components: {
  },
  props: {

  },
  data() {
    return {
      list: [],
      count: 0,
      tabHeight: 500,
      form: {
        "groupId": "", //用户选择的左树分区节点id，为空表示根节点或选择站点
        "stationId": "", //用户选择的左树站点节点ID，
        "name": "", //站点名称
        "code": "", //设备通信ID
        "type": 0, //站点类型
        "smallType": 0, //监控器设备类型
        "status": 0, //站点状态：0、全部；1、正常；2、维修；3、停用
        "online": 0, //在线状态：0、全部；1、在线；2、离线
        "alarm": 0, //报警状态：0、全部；1、报警；2、正常
        "running": 0, //亮灯状态：0、全部；1、亮灯；2、灭灯
        "index": 1, //分页码
        "size": 20, //分页大小
      }
    }
  },
  computed: {
    ...mapState('group', ['groupTreeSelectedNode']),
  },
  watch: {
    'groupTreeSelectedNode.id'() {
      this.getList();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.getList();
    setTimeout(this.setTableHeight, 200);
  },
  methods: {
    handlePageChange: function ({ currentPage, pageSize }) {
      this.form.index = currentPage;
      this.form.size = pageSize;
      this.getList(true);
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 20;
    },
    statusType(key) {
      let name = '正常'
      switch (key) {//1、正常；2、维修；3、停用
        case 1:
          name = '正常'
          break;
        case 2:
          name = '维修'
          break;
        case 3:
          name = '停用'
          break;
      }
      return name;
    },
    getList(is) {
      if (is) { this.form.index = 1; }
      if (this.groupTreeSelectedNode.typeTitle == "group") {
        this.form.groupId = this.groupTreeSelectedNode.id;
        this.form.stationId = '';
      } else if (this.groupTreeSelectedNode.typeTitle == "station") {
        this.form.groupId = this.groupTreeSelectedNode.groupId;
        this.form.stationId = this.groupTreeSelectedNode.id;
      }
      this.$axios.post(`api/powerbox/GetStationStateList`, this.form).then(res => {
        debugger;
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.count = res.data.count
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: calc(100%);
  height: calc(100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.table1-data {
  height: 100px;
  flex: auto;
}
</style>
