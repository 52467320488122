<template>
  <div class="page-box">
    <div class="list-query">
      名称：
      <Input v-model="form.name" type="text" clearable style="width: 100px; margin-right: 10px;"></Input>
      时间表：
      <Select v-model="form.timeId" style="width: 150px; margin-right: 10px;">
        <Option v-for="el,index in timeList" :key="index" :value="el.id">{{el.name}}</Option>
      </Select>
      控制通道号：
      <Select :placeholder="showLang('save.select')" v-model="form.channel" style="width: 200px; margin-right: 20px;">
        <Option :value="0">{{showLang('com.state.not')}}</Option>
        <Option v-for="n in 16" :key="n" :value="n">{{showLang('com.lighting.input.which',n)}}</Option>
      </Select>
      <Button type="primary" @click="getList()">查询</Button>
    </div>
    <div ref='table1' class="table1-data">
      <vxe-table border ref='xTable1' header-align="center" align="center" :height="tabHeight" :data="list">
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="lineName" title="通道名称"></vxe-column>
        <vxe-column field="stationName" title="所属站点"></vxe-column>
        <vxe-column field="channel" title="控制通道号"></vxe-column>
        <vxe-column field="output" title="关联输出"></vxe-column>
        <vxe-column field="currentRate" title="电流倍率"></vxe-column>
        <vxe-column field="currentRated" title="额定值"></vxe-column>
        <vxe-column field="curent" title="电流值"></vxe-column>
        <vxe-column field="currentLower" title="下限值"></vxe-column>
        <vxe-column field="currentUpper" title="上限值"></vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :current-page="form.index" :page-size="form.size" :total="count" :page-sizes="[20, 100, 500, 1000, 5000]" :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="handlePageChange">
    </vxe-pager>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'current',
  components: {
  },
  props: {

  },
  data() {
    return {
      list: [],
      timeList: [],
      count: 0,
      tabHeight: 500,
      form: {
        "groupId": "", //用户选择的左树分区节点id，为空表示根节点或选择站点
        "stationId": "", //用户选择的左树站点节点ID，
        "name": "", //站点名称
        "timeId": "", //关联时间表ID
        "channel": 0, //控制通道号，为0选全部
        "index": 1, //分页码
        "size": 20, //分页大小
      }
    }
  },
  computed: {
    ...mapState('group', ['groupTreeSelectedNode']),
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    'groupTreeSelectedNode.id'() {
      this.getList();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.GetTimeList();
    this.getList();
    setTimeout(this.setTableHeight, 200);
  },
  methods: {
    GetTimeList() {
      this.$axios.post(`api/powerbox/GetTimeList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeList', res.data);
        }
      });
    },
    handlePageChange: function ({ currentPage, pageSize }) {
      this.form.index = currentPage;
      this.form.size = pageSize;
      this.getList(true);
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 20;
    },
    getList(is) {
      if (is) { this.form.index = 1; }
      if (this.groupTreeSelectedNode.typeTitle == "group") {
        this.form.groupId = this.groupTreeSelectedNode.id;
        this.form.stationId = '';
      } else if (this.groupTreeSelectedNode.typeTitle == "station") {
        this.form.groupId = this.groupTreeSelectedNode.groupId;
        this.form.stationId = this.groupTreeSelectedNode.id;
      }
      this.$axios.post(`api/powerbox/GetCurrentStateList`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.count = res.data.count
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: calc(100%);
  height: calc(100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.table1-data {
  height: 100px;
  flex: auto;
}
</style>
