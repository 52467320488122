<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">漏电管理</div>
    <div>
      <Form ref="form" :model="form" :label-width="220">
        <FormItem prop="name" label="名称">
          <Input v-model="form.name" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="timeId" label="所属站点">
          <Select :placeholder="showLang('save.select')" v-model="form.stationId" style="width: 200px;">
            <Option v-for="(s, idx) in stationConfigList" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="channel" label="监测通道号">
          <Select :placeholder="showLang('save.select')" v-model="form.channel" style="width: 200px;">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <Option v-for="n in 16" :key="n" :value="n">{{showLang('com.lighting.input.which',n)}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="timeId" label="报警时间表">
          <Select :placeholder="showLang('save.select')" v-model="form.timeId" style="width: 200px;">
            <Option v-for="(s, idx) in timeList" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="rate" label="电流倍率">
          <Input v-model="form.rate" style="width: 200px"><span slot="append">倍</span></Input>
        </FormItem>
        <FormItem prop="level1" label="轻微漏电阈值">
          <Input v-model="form.level1" style="width: 200px"><span slot="append">mA</span></Input>
        </FormItem>
        <FormItem prop="level2" label="严重漏电阈值">
          <Input v-model="form.level2" style="width: 200px"><span slot="append">mA</span></Input>
        </FormItem>
        <FormItem prop="level3" label="保护漏电阈值">
          <Input v-model="form.level3" style="width: 200px"><span slot="append">mA</span></Input>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button type="info" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalleakageConfigEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      stationConfigList: [],
      timeList: [],
      indexVal: 0,
      form: {
        "id": "",
        "stationId": "", //所在站点
        "name": null, //名称
        "channel": 0, //监测通道号
        "timeId": "", //报警时间表ID
        "rate": 1, //电流倍率
        "level1": 30, //轻微漏电阈值
        "level2": 100, //严重漏电阈值
        "level3": 2000 //保护漏电阈值
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.dataAjax();
        if (this.item.isAdd) {
          this.form = {
            "id": "",
            "stationId": "", //所在站点
            "name": '', //名称
            "channel": 0, //监测通道号
            "timeId": "", //报警时间表ID
            "rate": 1, //电流倍率
            "level1": 30, //轻微漏电阈值
            "level2": 100, //严重漏电阈值
            "level3": 2000 //保护漏电阈值
          }
        } else {
          this.$set(this, 'form', this.item.data);
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
  },
  mounted: function () {
  },
  methods: {
    dataAjax() {
      this.$axios.post(`api/powerbox/GetTimeList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeList', res.data);
        }
      });
      let ajaxData = {
        "groupId": "", //用户选择的左树分区节点id，为空表示根节点或选择站点
        "stationId": "", //用户选择的左树站点节点ID，
        "name": "", //站点名称
        "code": "", //设备通信ID
        "type": 0, //站点类型
        "smallType": 0, //监控器设备类型
        "status": 0, //站点状态：0、全部；1、正常；2、维修；3、停用
        "online": 0, //在线状态：0、全部；1、在线；2、离线
        "alarm": 0, //报警状态：0、全部；1、报警；2、正常
        "running": 0, //亮灯状态：0、全部；1、亮灯；2、灭灯
        "index": 1, //分页码
        "size": 200000, //分页大小
      }
      this.stationConfigList = []
      if (this.groupTreeSelectedNode.typeTitle == "group") {
        ajaxData.groupId = this.groupTreeSelectedNode.id;
        ajaxData.stationId = '';
      } else if (this.groupTreeSelectedNode.typeTitle == "station") {
        this.stationConfigList.push({ id: this.groupTreeSelectedNode.id, name: this.groupTreeSelectedNode.name })
        return false
      }
      this.$axios.post(`api/powerbox/GetStationConfigList`, ajaxData).then(res => {
        if (res.code == 0) {
          this.$set(this, 'stationConfigList', res.data.list);
        }
      });
    },
    ok() {
      if (this.form.stationId <= 0) {
        this.$Message.warning('请选择所在站点');
        return;
      }
      if (this.form.timeId <= 0) {
        this.$Message.warning('请选择时间表');
        return;
      }
      if (this.form.level1 <= 0) {
        this.$Message.warning(this.showLang('save.light.range.leakage1', 0));
        return;
      }
      if (this.form.level2 <= this.form.level1) {
        this.$Message.warning(this.showLang('save.light.range.leakage2'));
        return;
      }
      if (this.form.level3 <= this.form.level2) {
        this.$Message.warning(this.showLang('save.light.range.leakage3'));
        return;
      }
      this.$axios.post(`api/powerbox/SaveLeakageConfig`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved");
          this.showModal = false;
        }
      });
    },
  }
}
</script>
<style scoped>
</style>
