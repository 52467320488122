<template>
  <div class="station-box">
    <template v-if="isShow=='group'">
      <listPage />
    </template>
    <template v-if="isShow=='station'">
      <mainPage />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import listPage from './list'
import mainPage from './main'
export default {
  name: 'station',
  components: {
    listPage,
    mainPage,
  },
  props: {

  },
  data() {
    return {
      isShow: 'group',//group station
    }
  },
  computed: {
    ...mapState('group', ['groupTreeSelectedNode']),
  },
  watch: {
    'groupTreeSelectedNode.id'() {
      this.isShow = this.groupTreeSelectedNode.typeTitle
    },
  },
  mounted: function () {
    this.isShow = this.groupTreeSelectedNode.typeTitle
  },
  methods: {

  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: calc(100%);
  height: calc(100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
</style>
