<template>
  <div class="page-box">
    <vxe-table border ref='xTable1' header-align="center" align="center" :row-config="{isHover: true}" :data="list" :radio-config="{ highlight: true}" class="mt20">
      <vxe-column type="seq" width="60"></vxe-column>
      <vxe-column field="name" title="名称"></vxe-column>
      <vxe-column field="isDefault" title="是否默认">
        <template #default="{ row }">
          {{row.isDefault?'是':'否'}}
        </template>
      </vxe-column>
      <vxe-column width="250" fixed="right">
        <template v-slot:header>
          <Button type="primary" @click="SaveTimeInfo(true)" class="ml10">新增</Button>
          <Button type="primary" @click="getList()" class="ml10">刷新</Button>
        </template>
        <template #default="{ row }">
          <Button type="primary" @click="SaveTimeInfo(false,row)" class="ml10">编辑</Button>
          <Button type="error" @click="DeleteTimeInfo(row)" class="ml10">删除</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <ModalTimeTable v-model="showModalTimeTable" :item='item' @saved='getList'></ModalTimeTable>
  </div>
</template>
<script>
import ModalTimeTable from './ModalTimeTable'
export default {
  name: 'timePage',
  components: {
    ModalTimeTable
  },
  props: {

  },
  data() {
    return {
      showModalTimeTable: false,
      item: {
        isAdd: true,
        data: {}
      },
      list: [],
    }
  },
  watch: {
  },
  mounted: function () {
    this.getList();
  },
  methods: {
    SaveTimeInfo(isAdd, el) {
      this.item.isAdd = isAdd;
      if (!isAdd) {
        this.item.data = el;
      }
      this.showModalTimeTable = true;
    },
    getList() {
      this.$axios.post(`api/powerbox/GetTimeList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data)
        }
      });
    },
    DeleteTimeInfo(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[ ${el.name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/powerbox/DeleteTimeInfo`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info('删除成功');
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
}
</style>
