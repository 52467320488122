<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">接触器</div>
    <div>
      <Form ref="form" :model="form" :label-width="220">
        <FormItem prop="name" label="名称">
          <Input v-model="form.name" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="outputId" label="关联控制输出">
          <Select :placeholder="showLang('save.select')" v-model="form.outputId" style="width: 200px;">
            <Option v-for="el,index in dataList" :key="index" :value="el.id">{{el.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="channel" label="控制通道号">
          <Select :placeholder="showLang('save.select')" v-model="form.channel" style="width: 200px;">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <Option v-for="n in 16" :key="n" :value="n">{{showLang('com.lighting.input.which',n)}}</Option>
          </Select>
        </FormItem>

      </Form>
    </div>
    <div slot="footer">
      <Button type="info" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalSwitchEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      dataList: [],
      form: {
        "id": "",
        "outputId": "", //关联控制输出
        "name": null, //名称
        "channel": 0, //控制通道号
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.dataAjax();
        if (this.item.isAdd) {
          this.form = {
            "id": "",
            "outputId": "", //关联控制输出
            "name": null, //名称
            "channel": 0, //控制通道号
          }
        } else {
          this.$set(this, 'form', this.item.data);
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
  },
  mounted: function () {
  },
  methods: {
    dataAjax() {
      let ajaxData = {
        "groupId": "", //用户选择的左树分区节点id，为空表示根节点或选择站点
        "stationId": "", //用户选择的左树站点节点ID，
        "name": "", //站点名称
        "timeId": "", //关联时间表ID
        "channel": 0, //控制通道号，为0选全部
        "index": 1, //分页码
        "size": 200000, //分页大小
      }
      this.dataList = []
      if (this.groupTreeSelectedNode.typeTitle == "group") {
        ajaxData.groupId = this.groupTreeSelectedNode.id;
        ajaxData.stationId = '';
      } else if (this.groupTreeSelectedNode.typeTitle == "station") {
        ajaxData.groupId = this.groupTreeSelectedNode.groupId;
        ajaxData.stationId = this.groupTreeSelectedNode.id;
      }
      this.$axios.post(`api/powerbox/GetOutputConfigList`, ajaxData).then(res => {
        if (res.code == 0) {
          this.$set(this, 'dataList', res.data.list);
        }
      });
    },
    ok() {
      this.$axios.post(`api/powerbox/SaveContactConfig`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved");
          this.showModal = false;
        }
      });
    },
  }
}
</script>
<style scoped>
</style>
