<template>
  <Modal v-model="showModal" :mask-closable="false" width="1220px">
    <div slot="header">{{showLang('com.edit.box.schedule')}}</div>
    <div class="total-container">
      <div class="float-container">
        <div>
          <Form :model="form" :label-width="40" inline style="margin-left: 20px">
            <FormItem :label="showLang('com.tab.title')">
              <Input ref="name" type="text" v-model="form.name" maxlength="20" style="width: 380px" :placeholder="showLang('save.name')"></Input>
            </FormItem>
            <FormItem :label="showLang('com.state.default')" :label-width="70">
              <i-switch v-model="form.isDefault" size="large" true-color="#13ce66" false-color="#ff4949">
                <span slot="open">{{showLang('com.op.yes')}}</span>
                <span slot="close">{{showLang('com.op.deny')}}</span>
              </i-switch>
            </FormItem>
            <FormItem :label-width="0">
              <Button type="primary" v-if="form.type !== '0'" @click="editDayAction(1)">{{showLang('timetable.batch.modify')}}</Button>
            </FormItem>
          </Form>
        </div>
        <div>
          <Button v-for="(item, idx) in months" :key="idx" :type="midx == idx ? 'primary':'default'" style="margin: 3px 4px; width: 71px" @click="switchMonth(idx)">{{showLang(item.lang)}}</Button>
        </div>
        <div class="edit-container">
          <div class="time-container">
            <div class="time-item" v-for="m in 31" :key="m" @click="editDayAction(0, m, midx)">
              <div class="time-title">{{m}}</div>
              <!-- {{midx + 1}}-{{m}} -->
              <p v-for="n in 4" :key="n">{{getAction(midx+1, m, n)}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="week-area">
        <div class="week-header">{{showLang('timetable.week.light.plan')}}</div>
        <div class="week-content">
          <div class="week-item" v-for="(item, idx) in form.weeks" :key="idx">
            {{weeks[item.week - 1]}} {{item.isAM ? showLang('com.date.am') : showLang('com.date.arvo')}}
            <i-switch v-model="item.enable" size="large" true-color="#13ce66" false-color="#ff4949" style="margin-left: 5px">
              <span slot="open">{{showLang('com.state.allow')}}</span>
              <span slot="close">{{showLang('com.state.prohibit')}}</span>
            </i-switch>
          </div>
        </div>
      </div>
      <ModalDayAction v-model="showDayModal" :item="dayEditItem" @dayActionSaved="dayActionSaved" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="saveTimeTable">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalDayAction from './ModalDayAction'
export default {
  name: 'ModalTimeTable',
  components: {
    ModalDayAction,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showDayModal: false,
      gpsEdit: true,
      months: [
        { name: '一月', lang: 'com.date.Jan' },
        { name: '二月', lang: 'com.date.Feb' },
        { name: '三月', lang: 'com.date.Mar' },
        { name: '四月', lang: 'com.date.Apr' },
        { name: '五月', lang: 'com.date.May' },
        { name: '六月', lang: 'com.date.Jun' },
        { name: '七月', lang: 'com.date.Jul' },
        { name: '八月', lang: 'com.date.Aug' },
        { name: '九月', lang: 'com.date.Sep' },
        { name: '十月', lang: 'com.date.Oct' },
        { name: '十一月', lang: 'com.date.Nov' },
        { name: '十二月', lang: 'com.date.Dec' }],
      weeks: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日',],
      midx: 0,
      days: 31,
      week: 0,
      showModal: this.value,
      isAdd: false,
      index: -1,
      loading: false,
      form: {
        id: 0,
        name: '',
        isDefault: false,
        weeks: [
          { week: 1, isAM: true, enable: true },
          { week: 1, isAM: false, enable: true },
          { week: 2, isAM: true, enable: true },
          { week: 2, isAM: false, enable: true },
          { week: 3, isAM: true, enable: true },
          { week: 3, isAM: false, enable: true },
          { week: 4, isAM: true, enable: true },
          { week: 4, isAM: false, enable: true },
          { week: 5, isAM: true, enable: true },
          { week: 5, isAM: false, enable: true },
          { week: 6, isAM: true, enable: true },
          { week: 6, isAM: false, enable: true },
          { week: 7, isAM: true, enable: true },
          { week: 7, isAM: false, enable: true },
        ],
        list: [],
      },
      dayEditItem: {},
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.isAdd = this.item.isAdd;
        this.index = this.item.index;
        if (this.isAdd) {
          this.form.id = '';
          this.form.name = '';
          // this.form.type = '1';
          this.form.isDefault = false;
          this.form.weeks = [
            { week: 1, isAM: true, enable: true },
            { week: 1, isAM: false, enable: true },
            { week: 2, isAM: true, enable: true },
            { week: 2, isAM: false, enable: true },
            { week: 3, isAM: true, enable: true },
            { week: 3, isAM: false, enable: true },
            { week: 4, isAM: true, enable: true },
            { week: 4, isAM: false, enable: true },
            { week: 5, isAM: true, enable: true },
            { week: 5, isAM: false, enable: true },
            { week: 6, isAM: true, enable: true },
            { week: 6, isAM: false, enable: true },
            { week: 7, isAM: true, enable: true },
            { week: 7, isAM: false, enable: true },
          ],
            this.form.list = [];
          for (let i = 1; i <= 12; i++) {
            for (let j = 1; j <= 31; j++) {
              this.form.list.push({
                "month": i,
                "day": j,
                "open1": 1090, //一段开灯时间，小时数乘以60加上分钟数，如：18:10 = 18*60 + 10 = 1090
                "close1": 360, //一段关灯时间
                "enable1": true,

                "enable2": false,
                "open2": 1080,
                "close2": 360,

                "enable3": false,
                "open3": 1080,
                "close3": 360,

                "enable4": false,
                "open4": 1080,
                "close4": 360
              })
            }
          }
        } else {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.isDefault = this.item.data.isDefault;
          this.getTableContent();
        }
        let now = new Date();
        let month = now.getMonth();
        this.switchMonth(month);
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
    // this.getTables();
    this.weeks = [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')]
  },
  computed: {
    ...mapState('common', ['mapCenter']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  methods: {
    saveTimeTable: async function () {
      if (!this.form.name || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('com.save.err.len.name', 20));
        return;
      }
      this.$axios.post(`api/powerbox/SaveTimeInfo`, this.form).then(res => {
        if (res.code == 0) {
          this.showModal = false;
          this.$emit('saved');
          this.$Message.info('设置成功');
        }
      });
    },
    dayActionSaved: function (params) {
      // // 0、单日年表，1、批量年表，2、单日周表
      if (params.type == 0) {
        let arr = this.form.list.find(el => el.month == (params.month + 1) && el.day == params.day)
        for (let i = 1; i <= 4; i++) {
          arr[`close${i}`] = this.convertTime2(params.action[`close${i}`]);
          arr[`open${i}`] = this.convertTime2(params.action[`open${i}`]);
          arr[`enable${i}`] = params.action[`enable${i}`];
        }
      }
      else if (params.type == 1) {
        let start = new Date(params.action.start);
        let end = new Date(params.action.end);
        let startMonth = start.getMonth(), startDay = start.getDate();
        let endMonth = end.getMonth(), endDay = end.getDate();
        let year = new Date().getFullYear();
        let i = startMonth, j = startDay;
        if (params.type == 1) {
          let index1 = this.form.list.findIndex(el => el.month == (startMonth + 1) && el.day == startDay)
          let index2 = this.form.list.findIndex(el => el.month == (endMonth + 1) && el.day == endDay)
          if (params.gps) {
            let day = new Date(`${year}-${i + 1}-${j}`);
            let riseAndSet = day.sunRiseAndSet(params.gpsForm.lat, params.gpsForm.lng);
            for (let index = index1; index < index2 + 1; index++) {
              for (let i = 1; i <= 4; i++) {
                if (params.action[`e${i}`]) {
                  let to=params.action[`ro${i}`] == '1'? riseAndSet.rise: riseAndSet.set;
                  let tc=params.action[`rc${i}`] == '1'? riseAndSet.rise: riseAndSet.set;
                  let do1 = parseInt(params.action[`do${i}`])
                  let dc1 = parseInt(params.action[`dc${i}`])
                  this.form.list[index][`close${i}`] = this.delayTime(to, do1, params.action[`bo${i}`]);
                  this.form.list[index][`open${i}`] = this.delayTime(tc, dc1, params.action[`bc${i}`]);
                  this.form.list[index][`enable${i}`] = params.action[`a${i}`];
                }
              }
            }
          } else {
            for (let index = index1; index < index2 + 1; index++) {
              for (let i = 1; i <= 4; i++) {
                if (params.action[`e${i}`]) {
                  this.form.list[index][`close${i}`] = this.convertTime2(params.action[`close${i}`]);
                }
                if (params.action[`eo${i}`]) {
                  this.form.list[index][`open${i}`] = this.convertTime2(params.action[`open${i}`]);
                }
                this.form.list[index][`enable${i}`] = params.action[`enable${i}`];
              }
            }
          }
        }

      }
    },
    convertTime2(time) {
      let ts = time.split(':');
      let h = parseInt(ts[0]), m = parseInt(ts[1]);
      let t = h * 60 + m;
      return t;
    },
    delayTime: function (time, delay, type) {//time: 05:34, delay: 5  type:1 正常  2 提前  3 延后 负  
      let ts = time.split(':');
      let h = parseInt(ts[0]), m = parseInt(ts[1]);
      if (type == 1) { delay = 0 }
      if (type == 3) { delay = delay * -1 }
      // let t = h * 60 + m + delay;
      // let h1 = parseInt(t / 60), m1 = t - h1 * 60;
      // let hs = `${h1}`.padStart(2, '0');
      // let ms = `${m1}`.padStart(2, '0');
      return h * 60 + m + delay;
    },
    convertTime: function (num) {//1090==>18:10(18*60 + 10 = 1090)
      let h = parseInt(num / 60)
      let m = num - h * 60;
      return `${h}`.padStart(2, '0') + ':' + `${m}`.padStart(2, '0');
    },
    editDayAction: function (type, day, month) {
      let action;
      if (type == 0) {
        action = this.form.list.find(el => el.day == day && el.month == (month + 1));
      } else {
        action = {
          start: '01-01',
          end: '12-31',
          weekEnables: [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')],
          open1: 1090,
          close1: 360,
          a1: 1,
          ro1: '2',
          bo1: '1',
          do1: '5',
          rc1: '1',
          bc1: '1',
          dc1: '5',
          e1: true,
          eo1: true,
          open2: 1090,
          close2: 360,
          a2: 2,
          ro2: '2',
          bo2: '1',
          do2: '5',
          ac2: '2',
          rc2: '1',
          bc2: '1',
          dc2: '5',
          e2: false,
          eo2: false,
          open3: 1090,
          close3: 360,
          a3: 2,
          ro3: '2',
          bo3: '1',
          do3: '5',
          ac3: '2',
          rc3: '1',
          bc3: '1',
          dc3: '5',
          e3: false,
          eo3: false,
          a4: 2,
          open4: 1090,
          close4: 360,
          ro4: '2',
          bo4: '1',
          do4: '5',
          ac4: '2',
          rc4: '1',
          bc4: '1',
          dc4: '5',
          e4: false,
          eo4: false,
        };
      }
      this.dayEditItem = { type: type, day: day, month: month, action: action };
      this.showDayModal = true;
    },
    getAction: function (month, day, idx) {
      let m = this.form.list.find(el => el.month == month && el.day == day);
      if (!m) return '00:00 ' + this.showLang('com.state.invalid');
      let enable = `${m['enable' + idx]}`;
      return `${this.convertTime(m['open' + idx])}-${this.convertTime(m['close' + idx])} ${enable?'开启':'关闭'}`;
    },
    switchMonth: function (idx) {
      this.midx = idx;
      let now = new Date();
      let year = now.getFullYear();
      let firstDate = new Date(`${year}-${idx + 1}-1`);
      this.week = firstDate.getDay();
      if (this.week == 0) {
        this.week = 7;
      }
      firstDate.setMonth(firstDate.getMonth() + 1);
      firstDate.setDate(firstDate.getDate() - 1);
      this.days = firstDate.getDate();
      // this.getTableContent();
    },
    getTableContent: async function () {
      this.loading = true;
      let res = await this.$axios.post(`api/powerbox/GetTimeDesc`, { id: this.form.id });
      this.loading = false;
      if (res.code == 0) {
        this.$set(this.form, 'list', res.data.list);
        this.$set(this.form, 'weeks', res.data.weeks);
      }

    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.total-container {
  /* border: solid 1px red; */
  height: 700px;
  display: flex;
}
.edit-container {
  height: calc(100% - 140px);
  /* border: solid 1px red; */
  overflow-y: auto;
  width: 970px;
}
.week-area {
  border: solid 1px #dcdee2;
  border-radius: 6px;
  width: 200px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.week-header {
  height: 40px;
  flex: none;
  line-height: 40px;
  text-align: center;
  border-bottom: solid 1px #dcdee2;
}
.week-content {
  height: 400px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.week-item {
  border-bottom: solid 1px #dcdee2;
  height: 35px;
  flex: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.float-container {
  width: 980px;
  flex: none;
  border: solid 1px #dcdee2;
  margin-right: 5px;
  border-radius: 6px;
  padding-top: 5px;
}
.time-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.time-item {
  border: solid 1px #4767aa;
  border-radius: 3px;
  width: 125px;
  height: 160px;
  background: linear-gradient(to top, #cccccc, #ffffff);
  font-family: 微软雅黑, 宋体;
  margin: 5px;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}
.time-item p {
  margin-top: 3px;
  white-space: nowrap;
}
.time-title {
  font-size: 22px;
  color: #4767aa;
  font-style: italic;
  font-weight: bold;
}
</style>